import {
    Card,
    Col,
    Descriptions,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Spin,
    Table,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { AddProductModal } from "./AddProductModal";
import { useEffect, useState } from "react";
import axios from "axios";
import { useProductListQuery } from "../GraphQl/generated/graphql";
import { API, Auth } from "aws-amplify";


export const ProductMgmt = () => {
    const [productData, setProductData] = useState([])
    const [isProductNameListLoading, setIsProductNameListLoading] = useState<boolean>(true)

    useEffect(() => {
        getProducts()
    }, [])

    const getProducts = async () => {

        try {
            let url = `/product_management?limit=2000&page=1/`;
            const response = await API.get(`latidoApi`, url, { response: true });
            setProductData(response?.data?.data?.data)
        } catch (error) {
            return false
        }
        finally {
            setIsProductNameListLoading(false)
        }

    }
    return (
        <Content style={{ display: "flex", justifyContent: 'center' }}>
            <Card>
                <AddProductModal productList={productData} productNameListLoading={isProductNameListLoading} />
            </Card>

        </Content>
    )
}
