import { Auth, Amplify } from "aws-amplify";
import { environment } from "./environment";

// dev
// export const PageURL = "http://bottle-projectl-web-mobile-dev.s3-website.ap-south-1.amazonaws.com"

// uat
export const PageURL = "https://www.new.m.uat.latido.com.np/";
// export const BaseURL = environment.baseUrl;

//uat
export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      region: environment.region,
      userPoolId: environment.userPoolId,
      userPoolWebClientId: environment.webClientId,
      mandatorySignIn: true,
      identityPoolRegion: "ap-south-1",
      identityPoolId: environment.identityPoolId,
      authenticationFlowType: environment.authFlow,

      //   oauth: {
      //     domain: 'uat.dlt9hlufm3mcb.amplifyapp.com',
      //     redirectSignIn: isLocalhost?'http://localhost:3000?signedin=true':`${PageURL}?signedin=true`,
      //     redirectSignOut: isLocalhost?'http://localhost:3000?signedin=false':`${PageURL}?signedin=false`,
      //     redirectSignIn: 'http://localhost:3000?signedin=true',
      //     redirectSignOut: 'http://localhost:3000?signedin=false',
      //     scope: ['profile', 'openid', 'email', 'phone'],
      //     responseType: 'token',
      //   },
    },

    Storage: {
      AWSS3: {
        bucket: environment.s3bucketName, //REQUIRED -  Amazon S3 bucket name
        region: "ap-south-1", //OPTIONAL -  Amazon service region
      },
    },

    API: {
      endpoints: [
        {
          name: "latidoApi",
          endpoint: environment.baseUrl,
          region: "ap-south-1",

          custom_header: async () => {
            const session = await Auth.currentSession();
            return {
              Authorization: session.getIdToken().getJwtToken(),
            };
          },
        },
      ],
    },
  });
};
