import { buildImageUrl, uploadImage } from "../config/amplifyS3";

export const productImageUpload = async (values: any) => {
  try {
    // console.log(values?.file?.originFileObj, 'FILE')
    const frontImageRequest = await uploadImage(
      `${values.file?.name}`,
      new Date().getTime()?.toString() || "",
      values?.file?.originFileObj
    );

    const uploadedImageUrl = buildImageUrl(frontImageRequest);
    // console.log("ImageResponse for amazon s3", frontImageRequest);
    return uploadedImageUrl;
  } catch (error) {
    console.error(error);
  }
};
