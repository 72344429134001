import { Button, Form, Input, InputNumber, Modal, Select, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import {
  useCreateAttributeMutation,
  useGetAttributesQuery,
  Enum_Attribute_Type,
} from "../GraphQl/generated/graphql";

export const Attributes = () => {
  const { data, loading, refetch } = useGetAttributesQuery();
  const [createAttribute, { data: createData, loading: creating }] =
    useCreateAttributeMutation();

  const [visible, setVisible] = useState(false);
  const onFinish = async (values: any) => {
    values.label = values.labels;
    delete values.labels;
    await createAttribute({
      variables: {
        data: {
          ...values,
        },
      },
    }).then(() => {
      refetch();
      setVisible(false);
    });
  };
  return (
    <Content>
      <Button onClick={() => setVisible(true)}>Add Attribute</Button>
      <Table
        rowKey='id'
        loading={loading}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "Type",
            dataIndex: "attributes",
            key: "attributes",
            render: (value, record, index) => value?.type,
          },
          {
            title: "Name",
            dataIndex: "attributes",
            key: "attributes",
            render: (value, record, index) => value?.name,
          },
          {
            title: "Label",
            dataIndex: "attributes",
            key: "attributes",
            render: (value, record, index) => value?.label,
          },
        ]}
        dataSource={data?.attributes?.data || []}
        pagination={{
          position: ["bottomCenter"],
        }}
      />
      <Modal
        destroyOnClose
        open={visible}
        onCancel={() => setVisible(false)}
        footer={false}>
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input size="large" />
          </Form.Item>
          <Form.Item name="labels" label="Label" rules={[{ required: true }]}>
            <Input size="large" />
          </Form.Item>
          <Form.Item name="type" label="Type" rules={[{ required: true }]}>
            <Select size="large">
              {/* {Object.keys(Enum_Attribute_Type)?.map((key) => { */}
              {/* return */}
              <Select.Option value={Enum_Attribute_Type.MinMax}>Min Max</Select.Option>;
              <Select.Option value={Enum_Attribute_Type.Single}>Single</Select.Option>;
              {/* })} */}
            </Select>
          </Form.Item>

          <Button loading={creating} htmlType="submit">
            SUBMIT
          </Button>
        </Form>
      </Modal>
    </Content>
  );
};
