import React, { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

interface PrivateRouteProps {
    children: React.ReactNode;
    redirectPath?: string;
    checkToken?: () => {}
}

export const ProtectedRoute: React.FC<PrivateRouteProps> = ({
    children,
    isHome = false,
    redirectPath = '/login',
    checkToken,
    ...rest
}: any) => {
    const authContext = useContext(AuthContext);
    if (!authContext) return null;

    const { isAuthenticated } = authContext



    console.log('Is user logged in final check', isAuthenticated);
    if (isAuthenticated) {
        return children
    }
    return <Navigate to={redirectPath} replace />;
};
