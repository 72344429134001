import {createContext} from 'react';

export interface IAuthContextType {
  isAuthenticated: boolean;
  setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
  token : () => Promise<boolean>;

}

export const AuthContext = createContext<IAuthContextType | undefined>(undefined);
