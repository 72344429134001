import {
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Layout,
    message,
    Modal,
    Row,
    Space,
    Tabs,
    TabsProps,
    Typography,
} from "antd";
import axios from "axios";
import { useContext, useState } from "react";
import {
    useCreateNodeMutation,
    useGetAttributesQuery,
    useGetAttributeValuesQuery, useGetNodesQuery
} from "../GraphQl/generated/graphql";
import { MinusCircleFilled } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { DemoDecompositionTreeGraph } from "../component/SampleGraph";
import { Attributes } from "../component/Attributes";
import { AttributeValues } from "../component/AttributeValues";
import { Nodes } from "../component/Nodes";
import { CustomerPortal } from "../component/CustomerPortal";
import { CustomerPortalV2 } from "../component/CustomerPortalV2";
import { ProductMgmt } from "../component/ProductMgmt";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";

function SizeModuleHome() {
    const { data } = useGetAttributesQuery({});
    const { data: attributeValue } = useGetAttributeValuesQuery({});
    const { data: nodes } = useGetNodesQuery({});

    const [createNode, { data: createData, loading }] = useCreateNodeMutation();

    const [bucket, setBucket] = useState<any>([]);

    const [visible, setVisible] = useState(false);

    const navigate = useNavigate();

    const authContext = useContext(AuthContext);
    if (!authContext) return null;

    const { setAuthenticated } = authContext



    const onFinish = async (values: any) => {
        await createNode({
            variables: {
                data: {
                    ...values,
                    attribute_values: bucket
                        ?.filter((bc: any) => bc?.__typename === "AttributeValueEntity")
                        ?.map((bc: any) => bc?.id),
                    nodes: bucket
                        ?.filter((bc: any) => bc?.__typename === "NodeEntity")
                        ?.map((bc: any) => bc?.id),
                },
            },
        });
    };

    const onLogout = async (values: any) => {

        try {
            await Auth.signOut();
            setAuthenticated(false)
            navigate("/login");
        } catch (error) {
            console.log('error signing out: ', error);
        }
    };

    const tabItems: TabsProps['items'] = [
        {
            key: '1',
            label: 'Data',
            children: <Content style={{ padding: 20 }}>
                <Row gutter={[40, 40]}>
                    <Col lg={12}>
                        <Card>
                            <Attributes />
                        </Card>
                    </Col>
                    <Col lg={12}>
                        <Card>
                            <AttributeValues />
                        </Card>
                    </Col>
                    <Col lg={24}>
                        <Card>
                            <Nodes />
                        </Card>
                    </Col>
                </Row>
            </Content>
        },
        {
            key: '2',
            label: 'Visualization',
            children: <DemoDecompositionTreeGraph />

        },
        {
            key: '3',
            label: 'Customer',
            children: <CustomerPortal />

        },
        {
            key: '4',
            label: 'CustomerV2',
            children: <CustomerPortalV2 />

        },
        {
            key: '5',
            label: 'Product Management',
            children: <ProductMgmt />

        },
    ]
    return (
        <Layout>
            <Content
                style={{
                    padding: 20,
                    display: "flex",
                    justifyContent: "space-between"
                }}>

                <Tabs items={tabItems} onChange={(key) => console.log(key, 'tabKeys')} />

                <Button size={'large'} onClick={onLogout} >Log Out</Button>

            </Content>

            <Modal open={visible} onCancel={() => setVisible(false)} footer={false}>
                <Form onFinish={onFinish} layout="vertical">
                    <Form.Item name="title" label="Node Title">
                        <Input />
                    </Form.Item>
                    <Form.Item name="output" label="Output">
                        <InputNumber />
                    </Form.Item>
                    <Form.Item name="outputLevel" label="Output Level">
                        <Input />
                    </Form.Item>
                    <Button htmlType="submit">SUBMIT</Button>
                </Form>
            </Modal>
        </Layout>
    );
}

export default SizeModuleHome;

