import React, { useMemo } from "react";
import ReactDOM from "react-dom";
import {
  DecompositionTreeGraph,
  DecompositionTreeGraphConfig,
  G6TreeGraphData,
} from "@ant-design/graphs";
import { product } from "../constants";
import { useGetAttributeValuesQuery, useGetNodesQuery } from "../GraphQl/generated/graphql";
import { Content } from "antd/es/layout/layout";

export const DemoDecompositionTreeGraph = () => {

  const { data: nodes, refetch } = useGetNodesQuery();
  const { data: attributes, refetch: refetchAtrribute } = useGetAttributeValuesQuery();
  const productChilds = product?.map((p) => {
    return {
      id: p.id?.toString() + Math.random(),
      value: {
        title: p.title,
        percent: 0.7,
        type: "node",
        items: [
          {
            text: p.title,
            value: p.id?.toString(),
          },
        ],
      },

    }
  })


  const getChildren = async (e: any) => {
    console.log(e, "click event")
    let newData: any = []
    if (e?.value?.type === "node") {
      const asyncData = await refetch({
        filters: {
          products: {
            title: {
              in: [e?.value?.title]
            }
          }
        }
      }).then((d) => {

        newData = d?.data?.nodes?.data?.map((node) => {
          return {
            id: e?.value?.title + "n" + node?.id?.toString(),
            value: {
              title: node?.attributes?.title,
              type: "attribute-value",
              id: node?.id,
              items: [
                {
                  text: node?.attributes?.title,
                  value: node?.id,
                },
                {
                  text: node?.attributes?.outputLevel,
                  value: node?.attributes?.output,
                },
              ],
            },

          }
        })
      })
    }
    else if (e?.value?.type === "attribute-value") {
      const asyncData = await refetchAtrribute({
        filters: {
          nodes: {
            id: {
              in: [e?.value?.id]
            }
          }
        }
      }).then((d) => {

        newData = d?.data?.attributeValues?.data?.map((node) => {
          return {
            id: e?.value?.id + "a" + node?.id?.toString(),
            value: {
              title: node?.attributes?.attribute?.data?.attributes?.name,
              type: "attribute-value",
              items: [
                {
                  text: "Min",
                  value: node?.attributes?.valueOne,
                },
                {
                  text: "Max",
                  value: node?.attributes?.valueTwo,
                },
                // {
                //   text: node?.attributes?.outputLevel,
                //   value: node?.attributes?.output,
                // },
              ],
            },

          }
        })
      })
    }


    return newData;
  };


  const data: G6TreeGraphData = {
    id: "Products",
    value: {
      title: "Products",
      items: [
        {
          text: "Total",
          value: product.length,
        },
      ],
      // percent: 0.8,
    },
    children: productChilds
  };
  const stroke = "#EA2F97";
  const config: DecompositionTreeGraphConfig = {
    data,
    nodeCfg: {
      getChildren,
      size: [140, 25],
      //   percent: {
      //     position: "bottom",
      //     size: 4,
      //     style: (arg: any) => {
      //       return {
      //         radius: [0, 0, 0, 2],
      //         fill: arg.value.percent > 0.3 ? stroke : "#1f8fff",
      //       };
      //     },
      //   },
      //   items: {
      //     containerStyle: {
      //       fill: "#fff",
      //     },
      //     padding: 6,
      //     style: (cfg, group, type) => {
      //       const styles = {
      //         icon: {
      //           width: 12,
      //           height: 12,
      //         },
      //         value: {
      //           fill: "#f00",
      //         },
      //         text: {
      //           fill: "#aaa",
      //         },
      //       };
      //       return styles[type];
      //     },
      //   },
      nodeStateStyles: {
        hover: {
          lineWidth: 2,
        },
      },

      title: {
        containerStyle: {
          fill: "transparent",
        },
        style: {
          fill: "#000",
          fontSize: 12,
        },
      },
      //   style: (arg) => {
      //     return {
      //       fill: "#fff",
      //       radius: 2,
      //       stroke: arg.value.percent > 0.3 ? stroke : "#1f8fff",
      //     };
      //   },
    },
    edgeCfg: {
      label: {
        style: {
          fill: "#aaa",
          fontSize: 12,
          fillOpacity: 1,
        },
      },
      style: (edge: any) => {
        return {
          stroke: "#518AD3",
          strokeOpacity: 0.5,
        };
      },
      endArrow: {
        fill: "#518AD3",
      },
      edgeStateStyles: {
        hover: {
          strokeOpacity: 1,
        },
      },
    },
    markerCfg: (cfg: any) => {
      return {
        position: "right",
        show: true,
        style: (arg: any) => {
          return {
            collapsed: !cfg?.children?.length,
            stroke: arg.value.percent > 0.3 ? stroke : "#1f8fff",
          };
        },
      };
    },
    behaviors: ["drag-canvas", "zoom-canvas", "drag-node"],
  };

  const MemoDecompositionTreeGraph = useMemo(() => {
    return <DecompositionTreeGraph {...config} />
  }, [])

  // return <Content style={{ height: "100vh", backgroundColor: "gray" }}>
  //   {MemoDecompositionTreeGraph}
  // </Content>;
  return MemoDecompositionTreeGraph;
};
