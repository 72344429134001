import { Storage } from "aws-amplify";
import { environment } from "./environment";
// import {BUCKET_URL} from '@env';

export const uploadImage = async (
  imageOf: String,
  filename: String,
  file: any
  // fetching: React.Dispatch<React.SetStateAction<boolean>>,
): Promise<string> => {
  let imageUrl = "";
  try {
    // console.log('imageOf', imageOf, 'filename', filename, 'file', file);
    // fetching(true);
    // const photo = await fetch(file.path as RequestInfo);
    // const photoBlob = await photo.blob();

    const result: any = await Storage.put(
      `profile/${imageOf}/${filename}`,
      file,
      {
        contentType: file.type,
      }
    );
    // imageUrl = `${BUCKET_URL}public/${result.key}`;
    imageUrl = "public/" + result?.key;
    // console.log('ImageUploadResult', result);
    // {
    //   imageUrl.length > 1 && fetching(false);
    // }
  } catch (err: any) {
    console.log("error uploading:", err?.response.data);
  }

  return imageUrl;
};

export const buildImageUrl = (imageKey: string) => {
  return environment.s3bucketUrl + imageKey;
};
