import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputNumber, Select, Space } from "antd";
import { useUpdateNodeMutation } from "../GraphQl/generated/graphql";
import { CustomFormListItem } from "./CustomFormListItem";


interface IEditNodeFormProps {
    listOfAttribute: any;
    productSelectOptions: any;
    nodeRowData: any;
    setEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}



export const EditNodeForm: React.FC<IEditNodeFormProps> = ({
    listOfAttribute,
    productSelectOptions,
    nodeRowData,
    setEditModalVisible }) => {

    const [
        updateNode,
        { data: updateNodeData, loading: updateNodeLoading, },
    ] = useUpdateNodeMutation();
    const onEditNodeFinish = async (values: any) => {
        // console.log('values form node EDIT', values)
        await updateNode({
            variables: {
                id: nodeRowData?.id,
                data: {
                    attribute_values: values.attribute_values,
                    // nodes: values.        ,
                    output: values.output,
                    outputLevel: values.outputLevel,
                    products: values.products.map((item: any) => {
                        const updatedValue = { title: item?.name }
                        return updatedValue

                    }),
                    title: values.title,
                },
            },
        }).then(() => {
            setEditModalVisible(false);
        });
    };
    return (
        <Form onFinish={onEditNodeFinish} layout="vertical">
            <Form.Item
                name="attribute_values"
                label="Attribute"
                rules={[{ required: true }]}
                initialValue={nodeRowData?.attributes?.attribute_values?.data.map((item: any) =>
                    item?.id
                    // `${item.attributes?.attribute?.data?.attributes?.name} (${item.attributes?.valueOne} - ${item.attributes?.valueTwo})`
                )}
            >
                <Select
                    size="large"
                    showSearch
                    mode="multiple"
                // onSelect={(e) =>
                //   setType(
                //     attributes?.attributes?.data?.find((att) => att?.id === e)
                //       ?.attributes?.type
                //   )
                // }
                >
                    {(Array.isArray(listOfAttribute) ? listOfAttribute : [])?.map((key) => {
                        return (
                            <Select.Option value={key?.id} key={key?.id} >
                                {`${key.attributes?.attribute?.data?.attributes?.name} (${key.attributes?.valueOne} - ${key.attributes?.valueTwo})`}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
            <Form.Item
                name="title"
                label="Node Title"
                rules={[{ required: true }]}
                initialValue={nodeRowData?.attributes?.title} >
                <Input size="large" />
            </Form.Item>
            <Form.Item name="output"
                label="Output"
                rules={[{ required: true }]}
                initialValue={nodeRowData?.attributes?.output} >
                <InputNumber size="large" />
            </Form.Item>
            <Form.Item
                name="outputLevel"
                label="Output Level"
                rules={[{ required: true }]}
                initialValue={nodeRowData?.attributes?.outputLevel} >

                <Input size="large" />
            </Form.Item>

            <CustomFormListItem productSelectOptions={productSelectOptions} nodeRowData={nodeRowData} />

            <Button loading={updateNodeLoading} htmlType="submit" size="large">
                SUBMIT
            </Button>
        </Form>
    )
}
