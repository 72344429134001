import {
  Card,
  Col,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
} from "antd";
import { Content } from "antd/es/layout/layout";
import {
  NodeFiltersInput,
  useGetAttributesQuery,
  useGetNodesQuery,
} from "../GraphQl/generated/graphql";
import { useForm } from "antd/es/form/Form";
import { useState } from "react";
import { product } from "../constants";
import { useDebounce } from "use-debounce";

export const GetExisted = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== 0 && !obj[key]) {
      delete obj[key];
    } else if (typeof obj[key] === "object" && obj[key]?.length === 0) {
      delete obj[key];
    }
  });

  return obj || {};
};

export const CustomerPortal = () => {
  const { data } = useGetAttributesQuery();
  const [filter, setFilter] = useState<NodeFiltersInput>();

  const [debounceFilter] = useDebounce(filter, 1000);
  const { data: nodeData, loading } = useGetNodesQuery({
    variables: {
      filters: debounceFilter,
    },
    skip: !filter?.and,
    notifyOnNetworkStatusChange: true,
  });

  const [form] = useForm();

  const handleChange = () => {
    const values = form?.getFieldsValue();

    const valid = form.getFieldsError();
    console.log(valid?.filter((v) => v?.errors?.length > 0));
    if (valid?.filter((v) => v?.errors?.length > 0).length > 0) {
      return false;
    }

    const product = { ...values };
    delete values?.product;
    // console.log(Object.keys(GetExisted(values))?.length, Object.keys(values)?.length, "compare")

    if (
      Object.keys(GetExisted(values))?.length !== Object.keys(values)?.length
    ) {
      console.log(values, "input false");

      return false;
    } else {
      // console.log(values, "input")
    }

    console.log(values, "valid");
    const singleValues = data?.attributes?.data
      ?.filter((d) => d.attributes?.type === "SINGLE")
      ?.map((md) => md.attributes?.name);
    console.log(singleValues, "values");
    setFilter({
      and: [
        {
          and: Object.keys(values)?.reduce((agg: any, val: any) => {
            return [
              ...agg,
              singleValues?.includes(val)
                ? {
                  attribute_values: {
                    //
                    valueOne: {
                      eq: values?.[val]?.toString(),
                    },
                    attribute: {
                      name: {
                        eq: val,
                      },
                    },
                    //
                  },
                }
                : {
                  attribute_values: {
                    //
                    valueOne: {
                      lte: values?.[val]?.toString(),
                    },
                    valueTwo: {
                      gte: values?.[val]?.toString(),
                    },
                    attribute: {
                      name: {
                        eq: val,
                      },
                    },
                    //
                  },
                },
            ];
          }, []),
        },
        {
          products: {
            title: {
              eq: product?.product,
            },
          },
        },
      ],
    });
  };
  return (
    <Content>
      <Row justify="center" gutter={[20, 20]}>
        <Col lg={8}>
          <Card
            style={{
              minHeight: "70vh",
            }}>
            <Form form={form} layout="vertical" onChange={handleChange}>
              <Form.Item
                key={11}
                label="Product"
                name="product"
                rules={[
                  {
                    required: true,
                    message: "Please select a product",
                  },
                ]}>
                <Select>
                  {product?.map(pr => {
                    return (
                      <Select.Option value={pr?.title} key={pr?.id}>
                        {pr?.title}
                      </Select.Option>
                    );
                  }
                  )}
                </Select>
              </Form.Item>
              {data?.attributes?.data?.map((att) => {
                return (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter a valuet",
                      },
                    ]}
                    key={att?.id}
                    label={att?.attributes?.label}
                    name={att?.attributes?.name || "a"}>
                    <Input />
                  </Form.Item>
                );
              })}
            </Form>
          </Card>
        </Col>
        <Col lg={8}>
          <Card
            style={{
              minHeight: "20vh",
            }}>
            {loading && <Spin />}
            <Descriptions title="Recommendation">
              <Descriptions.Item label="Size">
                {nodeData?.nodes?.data?.[0]?.attributes?.output}
              </Descriptions.Item>
              <Descriptions.Item label="Size Label">
                {nodeData?.nodes?.data?.[0]?.attributes?.outputLevel}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </Content>
  );
};
