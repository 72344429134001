import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Table,
  message,
  Upload,
  Image,
  Spin,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { UploadOutlined } from "@ant-design/icons";

import { useEffect, useState } from "react";
import {
  useCreateAttributeMutation,
  useGetAttributesQuery,
  Enum_Attribute_Type,
  useGetFitTypesQuery,
  ProductListFiltersInput,
  ProductListEntity,
  useProductListQuery,
  useCreateProductListMutation,
} from "../GraphQl/generated/graphql";
import { buildImageUrl, uploadImage } from "../config/amplifyS3";
import { useForm } from "antd/es/form/Form";
import { createProductList } from "../GraphQl/Mutations/createProductList";
import { useMutation } from "@apollo/client";
import { post } from "../config/amplifyRestAPIConfig";
import EditProductForm from "./EditProductForm";
import { productImageUpload } from "../utils/productImageUpload";

interface AddProductModalProps {
  productList: any;
  productNameListLoading: boolean;
}

interface PaginationState {
  page: number;
  pageSize: number;
}

export const AddProductModal: React.FC<AddProductModalProps> = ({
  productList,
  productNameListLoading,
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { data, loading: fitTypeLoading, refetch } = useGetFitTypesQuery();
  const listOfFitTypes = data?.attributeValues?.data;
  const [visible, setVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [productRowData, setProductRowData] = useState({});

  const [selectedFit, setSelectedFit] = useState<string>("");
  const [isProductSelectDisabled, setIsProductSelectDisabled] =
    useState<boolean>(true);

  const [pagination, setPagination] = useState<PaginationState>({
    page: 1,
    pageSize: 10,
  });

  const [form] = useForm();

  const [
    createProductList,
    { data: createProductData, loading: createProductLoading, error },
  ] = useCreateProductListMutation();

  const {
    data: productListQueryData,
    loading: productTableListLoading,
    refetch: productListRefetch,
  } = useProductListQuery({
    variables: {
      pagination: {
        ...pagination,
      },
    },
  });
  // const [
  //     createProductList,
  //     { data: createProductData, loading: creatingProduct },
  // ] = useCreateProductListMutation();

  const tableProductsDisplayData = productListQueryData?.productLists;
  // console.log('tableProductsDisplayData', tableProductsDisplayData)
  // useEffect(() => console.log('updated'), [currentProductId]);

  const onFinish = async (values: any) => {
    // console.log("FORM VALUES", values);
    // values.label = values.labels;
    // delete values.labels;
    setSubmitting(true);
    //Spread the object and convert Promise value to string
    const MUTATION_PAYLOAD = {
      product_name: values?.product?.value,
      productId: values?.product_id,
      Fit: values?.fit?.value,
      publishedAt: new Date().toISOString(),
      BackImage: await values?.back_image,
      FrontImage: await values?.front_image,
    };

    try {
      await createProductList({
        variables: {
          data: MUTATION_PAYLOAD,
        },
      });
      // console.log(response, "Mutation values");

      const restResponseInOnFinish = await addProductsToRest(values);
      productListRefetch();
      setVisible(false);
      form.resetFields();
    } catch (error: any) {
      console.error(error.message, "graphql validation error");
      form.setFields([
        {
          name: "product",
          errors: [error?.message],
        },
      ]);
    } finally {
      setSubmitting(false); // Enable the submit button
    }
  };

  const handleSelectProduct = (value: any) => {
    form.setFieldsValue({
      product_id: value?.key,
    });
  };

  const addProductsToRest = async (values: any) => {
    const REST_API_PAYLOAD = {
      name: values?.product?.value,
      productId: values?.product_id,
      fitData: {
        frontImage: await values?.front_image,
        backImage: await values?.back_image,
        fit: values?.fit?.value,
      },
    };

    let url = `/media_management`;
    const response = await post(`latidoApi`, url, { body: REST_API_PAYLOAD });
    return response;
  };

  const editProductRowRecord = (record: {}) => {
    console.log("record row", record);
    setEditModalVisible(true);
    setProductRowData(record);
  };

  return (
    <Content
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Button
        style={{ alignSelf: "flex-start" }}
        onClick={() => setVisible(true)}
      >
        Add Product
      </Button>
      <Table
        style={
          {
            // display: "flex",
            // flex: 1
          }
        }
        rowKey="id"
        loading={productTableListLoading}
        columns={[
          {
            title: "Id",
            dataIndex: "id",
            key: "1",
          },
          {
            title: "Product Name",
            dataIndex: ["attributes", "product_name"],
            key: "2",
          },
          {
            title: "Product ID",
            dataIndex: ["attributes", "productId"],
            key: "3",
          },
          {
            title: "FRONT IMAGE",
            dataIndex: ["attributes", "FrontImage"],
            key: "4",
            render: (item, image) => {
              return (
                <Image
                  key={image?.id}
                  width={50}
                  height={50}
                  src={item}
                  alt={`${image?.attributes?.product_name}`}
                  // placeholder={
                  //     <Image
                  //         preview={false}
                  //         src=""
                  //         width={50}
                  //         height={50}

                  //     />
                  // }
                />
              );
            },
          },
          {
            title: "Fit",
            dataIndex: ["attributes", "Fit"],
            key: "5",
          },
          {
            title: "BACK IMAGE",
            dataIndex: ["attributes", "BackImage"],
            key: "6",
            render: (item, image) => (
              <Image
                key={image?.id}
                width={50}
                height={50}
                src={item}
                alt={`${image?.attributes?.product_name}`}

                // placeholder={
                //     <Image
                //         preview={false}
                //         src=""
                //         width={200}
                //     />
                // }
              />
            ),
          },
          {
            title: "",
            key: "7",
            render: (text, record, index) => {
              return (
                <Button onClick={() => editProductRowRecord(record)}>
                  Edit
                </Button>
              );
            },
          },
        ]}
        dataSource={
          Array.isArray(tableProductsDisplayData?.data)
            ? tableProductsDisplayData?.data
            : []
        }
        pagination={{
          position: ["bottomCenter"],
          onChange(page, pageSize) {
            setPagination({ page: page, pageSize: pageSize });
          },
          pageSize: pagination?.pageSize,
          total: tableProductsDisplayData?.meta?.pagination?.total,
        }}
      />
      <Modal
        destroyOnClose
        open={visible}
        onCancel={() => {
          setVisible(false);
          setSelectedFit("");
          setIsProductSelectDisabled(true);
          form.resetFields();
        }}
        footer={false}
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          defaultValue="product_id: Helllo"
        >
          <Form.Item name="fit" label="Fit" rules={[{ required: true }]}>
            <Select
              size="large"
              loading={fitTypeLoading}
              labelInValue
              onSelect={(e) => {
                setSelectedFit(`_${e.value}`);
                setIsProductSelectDisabled(false);
                form.resetFields(["product", "product_id"]);
              }}
            >
              {(Array.isArray(listOfFitTypes) ? listOfFitTypes : [])?.map(
                (item) => {
                  return (
                    <Select.Option
                      value={item?.attributes?.valueOne}
                      key={item?.id}
                    >
                      {item?.attributes?.valueOne}
                    </Select.Option>
                  );
                }
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="product"
            label="Product"
            rules={[{ required: true }]}
          >
            <Select
              showSearch
              loading={productNameListLoading}
              size="large"
              labelInValue
              disabled={isProductSelectDisabled}
              onSelect={handleSelectProduct}
            >
              {(Array.isArray(productList) ? productList : [])?.map((item) => {
                return (
                  <Select.Option
                    value={`${item?.name}${selectedFit}${
                      item?.gender === "unisex" ? "" : `_${item?.gender}`
                    }`}
                    key={item?.id}
                  >
                    {`${item?.name}${selectedFit}${
                      item?.gender === "unisex" ? "" : `_${item?.gender}`
                    }`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="product_id" label="Product ID">
            <Input size="large" disabled={true} />
          </Form.Item>

          <Form.Item
            name="front_image"
            label="Front Image"
            rules={[{ required: true }]}
            getValueFromEvent={productImageUpload}
          >
            <Upload
              listType="picture"
              className="upload-list-inline"
              maxCount={1}
              // onChange={}
            >
              <Button icon={<UploadOutlined />}>Upload Front Image</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="back_image"
            label="Back Image"
            rules={[{ required: true }]}
            getValueFromEvent={productImageUpload}
          >
            <Upload
              listType="picture"
              className="upload-list-inline"
              maxCount={1}
              onChange={productImageUpload}
            >
              <Button icon={<UploadOutlined />}>Upload Back Image</Button>
            </Upload>
          </Form.Item>

          <Button
            htmlType="submit"
            disabled={submitting}
            loading={createProductLoading}
          >
            SUBMIT
          </Button>
        </Form>
      </Modal>

      <Modal
        destroyOnClose
        open={editModalVisible}
        onCancel={() => {
          setEditModalVisible(false);
          setProductRowData({});
          // form.resetFields();
        }}
        footer={false}
      >
        <EditProductForm
          listOfFitTypes={listOfFitTypes}
          productSelectOptions={productList}
          productRowData={productRowData}
          setEditModalVisible={setEditModalVisible}
          productNameListLoading={productNameListLoading}
        />
      </Modal>
    </Content>
  );
};
