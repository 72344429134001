import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Image, Input, Select, Upload } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useState } from "react";
import { UploadChangeParam } from "antd/es/upload";
import { useUpdateProductListMutation } from "../GraphQl/generated/graphql";
import { patch } from "../config/amplifyRestAPIConfig";
import { productImageUpload } from "../utils/productImageUpload";

interface EditProductModalProps {
  listOfFitTypes: any;
  productRowData: any;
  productSelectOptions: any;
  productNameListLoading?: boolean;
  setEditModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditProductForm: React.FC<EditProductModalProps> = ({
  listOfFitTypes,
  productRowData,
  productSelectOptions,
  productNameListLoading,
  setEditModalVisible,
}) => {
  // console.log('productEditData', productRowData)
  const [editSubmitting, setEditSubmitting] = useState<boolean>(false);
  const [editFormSelectedFit, setEditFormSelectedFit] = useState<string>(
    productRowData?.attributes?.attribute_value?.data?.attributes?.valueOne
  );
  const [selectedProductId, setSelectedProductId] = useState("");
  const [isProductSelectDisabled, setIsProductSelectDisabled] =
    useState<boolean>(true);
  const [frontImageValue, setFrontImageValue] = useState(
    productRowData?.attributes?.BackImage
  );

  const [backImageValue, setBackImageValue] = useState(
    productRowData?.attributes?.FrontImage
  );

  const [file, setFile] = useState<File | null>(null);

  const [
    updateProductList,
    {
      data: updateProductData,
      loading: updateProductLoading,
      // refetch: productEditRefetch,
    },
  ] = useUpdateProductListMutation();

  const [form] = useForm();

  const handleSelectFit = (value: any) => {
    console.log("fit value changed", value);
    setEditFormSelectedFit(`_${value.label}`);
    setIsProductSelectDisabled(false);
    form.setFieldsValue({
      product: "",
      product_id: "",
    });
  };

  const handleSelectProduct = (value: any) => {
    form.setFieldsValue({
      product_id: value?.key,
    });
  };

  // Custom validation function for file type
  const validateFile = (file: File): boolean => {
    console.log("file_validate", file);
    const allowedTypes = ["image/*"]; // Add allowed image file types
    if (!allowedTypes.includes(file.type)) {
      // message.error('Only JPG/PNG files are allowed');
      console.error("Only JPG / PNG files are allowed");
      return false;
    }
    return true;
  };

  const handlePhotoChange = (info: UploadChangeParam) => {
    console.log("info_image_face", info);
    if (info.file.status === "done" && info.fileList.length === 1) {
      setFile(info.file.originFileObj as File);
    } else {
      setFile(null);
    }
  };

  const onFinish = async (values: any) => {
    console.log("FORM VALUES", values);
    setEditSubmitting(true);
    // values.label = values.labels;
    // delete values.labels;
    // console.log('ID', productRowData?.id)
    // Spread the object and convert Promise value to string
    const MUTATION_PAYLOAD = {
      product_name: values?.product?.value,
      productId: values?.product_id,
      Fit: values?.fit?.value,
      publishedAt: new Date().toISOString(),
      BackImage: await values.back_image,
      FrontImage: await values.front_image,
    };
    console.log("MUTATION_PAYLOAD", MUTATION_PAYLOAD);
    try {
      await updateProductList({
        variables: {
          id: productRowData?.id,
          data: MUTATION_PAYLOAD,
        },
      });
      // console.log(response, "Mutation values");
      // refetch();

      const restPatchResponseInOnFinish = await editProductAndPatch(values);
      console.log("restPatchResponseInOnFinish", restPatchResponseInOnFinish);

      setEditModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error(error, "Apollo Error");
    } finally {
      setEditSubmitting(false);
    }
  };

  const editProductAndPatch = async (values: any) => {
    const REST_API_PAYLOAD = {
      name: values?.product?.value,
      productId: values?.product_id,
      fitData: {
        frontImage: await values?.front_image,
        backImage: await values?.back_image,
        fit: values?.fit?.value,
      },
    };
    console.log("REST_API_PAYLOAD", REST_API_PAYLOAD);
    let url = `/media_management/${REST_API_PAYLOAD.name}`;
    const response = await patch(`latidoApi`, url, { body: REST_API_PAYLOAD });
    return response;
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      defaultValue="product_id: Edit_Product"
    >
      <Form.Item
        name="fit"
        label="Fit"
        rules={[{ required: true }]}
        initialValue={productRowData?.attributes?.Fit}
      >
        <Select
          size="large"
          // loading={fitTypeLoading}
          labelInValue
          // onSelect={handleSelectFit}
          onChange={handleSelectFit}
        >
          {(Array.isArray(listOfFitTypes) ? listOfFitTypes : [])?.map(
            (item) => {
              return (
                <Select.Option
                  value={item?.attributes?.valueOne}
                  key={item?.id}
                >
                  {item?.attributes?.valueOne}
                </Select.Option>
              );
            }
          )}
        </Select>
      </Form.Item>

      <Form.Item
        name="product"
        label="Product"
        initialValue={productRowData?.attributes?.product_name}
        rules={[{ required: true }]}
      >
        <Select
          showSearch
          loading={productNameListLoading}
          size="large"
          labelInValue
          disabled={isProductSelectDisabled}
          onSelect={handleSelectProduct}
        >
          {(Array.isArray(productSelectOptions)
            ? productSelectOptions
            : []
          )?.map((item) => (
            <Select.Option
              value={`${item?.name}${editFormSelectedFit}${
                item?.gender === "unisex" ? "" : `_${item?.gender}`
              }`}
              key={item?.id}
            >
              {`${item?.name}${editFormSelectedFit}${
                item?.gender === "unisex" ? "" : `_${item?.gender}`
              }`}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="product_id"
        label="Product ID"
        initialValue={productRowData?.attributes?.productId}
      >
        <Input
          size="large"
          disabled={true}
          // value={selectedProductId}
        />
      </Form.Item>

      <Form.Item
        name="front_image"
        label="Front Image"
        rules={[{ required: true }]}
        initialValue={productRowData?.attributes?.FrontImage}
        // valuePropName='file'
        getValueFromEvent={productImageUpload}
      >
        <Upload
          listType="picture"
          className="upload-list-inline"
          maxCount={1}
          onChange={productImageUpload}
          // accept={"image/*"}
        >
          <Button icon={<UploadOutlined />}>Upload Front Image</Button>
        </Upload>
      </Form.Item>

      <Form.Item
        name="back_image"
        label="Back Image"
        rules={[{ required: true }]}
        initialValue={productRowData?.attributes?.BackImage}
        // valuePropName='fileList'
        getValueFromEvent={productImageUpload}
      >
        <Upload
          listType="picture"
          className="upload-list-inline"
          maxCount={1}
          // style={{ display: "flex" }}
          // fileList={file ? [file] : []}
          // beforeUpload={validateFile}
          onChange={productImageUpload}
          // accept={"image/*"}
        >
          <Button icon={<UploadOutlined />}>Upload Back Image</Button>
        </Upload>
      </Form.Item>

      <Button
        htmlType="submit"
        disabled={editSubmitting}
        loading={updateProductLoading}
      >
        SUBMIT
      </Button>
    </Form>
  );
};

export default EditProductForm;
