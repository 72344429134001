

import { Form, Input, Button, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { checkToken } from '../App';
import { useContext, useState } from 'react';
import { AuthContext } from '../contexts/AuthContext';

// interface LoginPageProps {
//     authLoading: boolean; // Use the previously defined type
// }

const LoginPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [form] = Form.useForm();

    const navigate = useNavigate();
    const authContext = useContext(AuthContext)

    const onFinish = async (values: any) => {
        setIsLoading(true)
        try {
            await Auth.signIn(values?.username, values?.password)
                .then((response) => {
                    // console.log(response, "AUTH SUCCESS RESPONSE")
                    if (response) {
                        authContext?.setAuthenticated(true)
                        navigate("/");
                    }
                    // toast.success('Logged in successfully!');
                    // checkToken();
                })
                .catch((err: {}) => {
                    // toast.error(
                    //     parseError(err)?.message || 'We are not able to sign you in!',
                    // );

                    console.log("Auth signIn error", err);
                });
        } catch (error) {
            // toast.error('We are not able to sign you in!');
            // console.log(parseError(error));

            console.log("Auth signIn error", error);

        } finally {
            setIsLoading(false)
        }

        form.resetFields();


    };

    return (
        <Card style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Card type='inner' style={{}} >
                <Form
                    form={form}
                    name="normal_login"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Username!',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined />} autoComplete='off' placeholder="Username" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined />}
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>


                    <Form.Item style={{ display: 'flex', justifyContent: 'center' }} >
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Card></Card>
    );
};

export default LoginPage

