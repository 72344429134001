import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import { Content } from "antd/es/layout/layout";
import {
  useGetAttributesQuery,
  useGetFitTypesQuery,
  useGetNodesLazyQuery,
} from "../GraphQl/generated/graphql";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { FeetToCMConvert } from "../utils/FeetToCMConvert";

export const GetExisted = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== 0 && !obj[key]) {
      delete obj[key];
    } else if (typeof obj[key] === "object" && obj[key]?.length === 0) {
      delete obj[key];
    }
  });

  return obj || {};
};
interface InputValues {
  product: string;
  Weight: string;
  Height: string;
  Fit: string;
  Gender: string;
  Age: string;
}

export const CustomerPortalV2 = () => {
  const [form] = useForm();

  const { data } = useGetAttributesQuery();

  const { data: fitTypeData } = useGetFitTypesQuery()
  const listOfFitTypes = fitTypeData?.attributeValues?.data

  const [product, setProduct] = useState<any[]>([])
  const [isProductNameListInV2Loading, setIsProductNameListInV2Loading] = useState<boolean>(true)

  const [getNodes, { data: nodeData, loading: nodeQueryLoading }] = useGetNodesLazyQuery({
    notifyOnNetworkStatusChange: true,
    // skip: true,
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
  });
  console.log('nodeData===>', nodeData, 'loading ===>', nodeQueryLoading)

  useEffect(() => {
    getProducts()
  }, [])

  const getProducts = async () => {
    try {
      let url = `/product_management?limit=2000&page=1/`;
      const response = await API.get(`latidoApi`, url, { response: true });
      setProduct(response?.data?.data?.data)
    } catch (error) {
      return false
    } finally {
      setIsProductNameListInV2Loading(false)
    }
  }

  const onFinish = (values: any,) => {
    console.log('form values====>', values)
    const queryNodeValues = {
      product: values.product,
      Weight: values.Weight,
      Height: values.Height,
      Fit: values.Fit,
      Gender: values.Gender,
      Age: values.Age,
    }
    getNodes({
      variables:
      {
        filters: {
          and: [
            { products: { title: { in: [queryNodeValues.product] } } },
            {
              attribute_values: {
                and: [
                  {
                    attribute:
                      { name: { in: ["Fit"] } }
                  },
                  { valueOne: { in: [queryNodeValues.Fit] } }

                ]
              }
            },
            {
              attribute_values: {
                and: [
                  {
                    attribute:
                      { name: { in: ['Age'] } }
                  },
                  { valueOne: { lte: queryNodeValues?.Age } },
                  { valueTwo: { gte: queryNodeValues?.Age } }

                ]
              }
            },
            {
              attribute_values: {
                and: [
                  {
                    attribute:
                      { name: { in: ['Gender'] } }
                  },
                  { valueOne: { in: [queryNodeValues.Gender] } }

                ]
              }
            },
            {
              attribute_values: {
                and: [
                  {
                    attribute:
                      { name: { in: ['Weight'] } }
                  },
                  { valueOne: { lte: queryNodeValues?.Weight } },
                  { valueTwo: { gte: queryNodeValues?.Weight } }

                ]
              }
            },
            {
              attribute_values: {
                and: [
                  {
                    attribute:
                      { name: { in: ['Height'] } }
                  },
                  { valueOne: { in: queryNodeValues?.Height } }
                ]
              }
            }
          ]
        }
      },
    })
  }

  return (
    <Content>
      <Row gutter={[20, 20]}>
        {/* <Col lg={8}>
          <Card
            bodyStyle={{
              height: "90vh",
              overflowY: "auto",
              backgroundColor: "black",
              color: "white",
            }}>
            <h5>INPUT LOG</h5>
            <Divider style={{ borderColor: "#cccccc" }} />
            <ul>
              <li key={uuidv4()}>
                <code>{'product'}</code> - <b>{inputs?.product}</b>
              </li>
              <li key={uuidv4()}>
                <code>{'Weight'}</code> - <b>{inputs?.Weight}</b>
              </li><li key={uuidv4()}>
                <code>{'Height'}</code> - <b>{inputs?.Height}</b>
              </li><li key={uuidv4()}>
                <code>{'Fit'}</code> - <b>{inputs?.Fit}</b>
              </li><li key={uuidv4()}>
                <code>{'Gender'}</code> - <b>{inputs?.Gender}</b>
              </li><li key={uuidv4()}>
                <code>{'Age'}</code> - <b>{inputs?.Age}</b>
              </li>
            </ul>

            <h5>FETCH STATUS</h5>
            <Divider style={{ borderColor: "#cccccc" }} />
            <h5>Searching Nodes</h5>
            {nodeQueryLoading ? (
              <HourglassFilled style={{ color: "yellowgreen" }} />

            ) : (<CheckCircleFilled style={{ color: "green" }} />)
            }

          </Card>
        </Col> */}
        <Col lg={12}>
          <Card
            style={{
              minHeight: "70vh",
            }}>
            <Form form={form} layout="vertical" onFinish={onFinish}
              onFinishFailed={(e) => console.log('Error finish failed', e)} >
              <Form.Item
                key={11}
                label="Product"
                name="product"
                rules={[
                  {
                    required: true,
                    message: "Please select a product",
                  },
                ]}>
                <Select loading={isProductNameListInV2Loading} showSearch>
                  {product?.map((item) => {
                    return (
                      <Select.Option value={item?.name} key={item?.id}>
                        {item?.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {data?.attributes?.data?.map((att) => {
                return (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Please enter a value",
                      },
                    ]}
                    key={att?.id}
                    label={att?.attributes?.label}
                    name={att?.attributes?.name || "a"}>
                    {att?.attributes?.name === 'Fit' ? <Select >
                      {listOfFitTypes?.map((item) => {
                        return (
                          <Select.Option value={item?.attributes?.valueOne} key={item?.id}>
                            {item?.attributes?.valueOne}
                          </Select.Option>
                        );
                      })}
                    </Select> : <Input size="large" />}
                  </Form.Item>
                );
              })}
              <Button htmlType="submit">SUBMIT</Button>
              <Button onClick={() => form.resetFields()
              } >RESET</Button>


            </Form>
          </Card>
        </Col>

        <Col lg={12}>
          <Card
            style={{
              minHeight: "20vh",
            }}>
            {nodeQueryLoading && <Spin />}
            <>
              <Descriptions
                title={nodeData?.nodes?.data.length === 0 ? `Recommendation (No Matches Found)` : 'Recommendation'}
                column={2}
                contentStyle={{ fontWeight: "bold" }}>
                <Descriptions.Item label="ID">
                  {nodeData?.nodes?.data?.[0]?.id}
                </Descriptions.Item>
                <Descriptions.Item label="Size">
                  {nodeData?.nodes?.data?.[0]?.attributes?.output}
                </Descriptions.Item>
                <Descriptions.Item label="Size Label">
                  {nodeData?.nodes?.data?.[0]?.attributes?.outputLevel}
                </Descriptions.Item>
              </Descriptions>

              {nodeData?.nodes?.data && nodeData?.nodes?.data?.length > 0 && (
                <>
                  <h5>OTHER POSSIBILITIES</h5>
                  <Table
                    pagination={false}
                    dataSource={nodeData?.nodes?.data?.slice(1, 10)}
                    rowKey='id'
                    columns={[
                      {
                        title: "Id",
                        key: '1',
                        dataIndex: "attributes",
                        render(value, record, index) {
                          return record?.id;
                        },
                      },
                      {
                        title: "Output",
                        key: '2',
                        dataIndex: "attributes",
                        render(value, record, index) {
                          return value?.output;
                        },
                      },
                      {
                        title: "Output",
                        key: '3',
                        dataIndex: "attributes",
                        render(value, record, index) {
                          return value?.outputLevel;
                        },
                      },
                    ]}
                  />
                </>
              )}</>
          </Card>
        </Col>
      </Row>
    </Content>
  );
};
