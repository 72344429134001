import { Button, Form, Input, InputNumber, Modal, Select, Space, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import {
  Enum_Activity_Type,
  useCreateAttributeMutation,
  useCreateAttributeValueMutation,
  useGetAttributesQuery,
  useGetAttributeValuesQuery,
} from "../GraphQl/generated/graphql";
import { FeetToCMConvert } from "../utils/FeetToCMConvert";
import { Switch } from "antd";
import { useForm } from "antd/es/form/Form";

export const AttributeValues = () => {
  const { data: attributes } = useGetAttributesQuery();
  const {
    data,
    loading: attributeValueLoading,
    error: attributeValueError,
    refetch,
  } = useGetAttributeValuesQuery({
    variables: {
      pagination: {
        limit: -1,
      },
    },
  });
  const [tableData, setTableData] = useState<any>([])

  useEffect(() => {
    if (!attributeValueLoading && !attributeValueError && data) {
      // Data has loaded, and there are no errors
      // You can set your state here based on the data
      setTableData(data?.attributeValues?.data); // Replace 'someValue' with the actual data you want to use
    }
  }, [data,
    attributeValueLoading,
    attributeValueError]);

  const [createAttribute, { data: createData, loading: creating }] =
    useCreateAttributeValueMutation();

  const [visible, setVisible] = useState(false);

  const checkDuplicateSingleValue = (attribute: any, valueOne: string) => {
    // console.log('attributessss', attribute)
    if (type === 'SINGLE') {
      console.log('SINGLE')
      const filteredSingleValues = data?.attributeValues?.data.filter(item =>
        item?.attributes?.attribute?.data?.attributes?.name === attribute?.label
        && item?.attributes?.valueOne === valueOne
      )
      return filteredSingleValues?.length
    }
    return 0

  }

  const checkDuplicate_MIN_MAX_Values = (attribute: any, valueOne: string, valueTwo: string) => {
    if (type === 'MIN_MAX') {
      console.log('MIN_MAX')
      const filtered_MIN_MAX_Values = data?.attributeValues?.data.filter(item =>
        item?.attributes?.attribute?.data?.attributes?.name === attribute?.label
        && item?.attributes?.valueOne === valueOne
        && item?.attributes?.valueTwo === valueTwo
      )
      return filtered_MIN_MAX_Values?.length
    }
    return 0
  }

  const onFinish = async (values: any) => {
    values.label = values.labels;
    delete values.labels;
    console.log('check value label', values)
    // if (values?.attribute?.label === "Height") {
    //   if (selectFeet) {
    //     const tempValueOne = values.valueOne;
    //     const tempValueTwo = values.valueTwo;

    //     values.valueOne = FeetToCMConvert(tempValueOne);
    //     values.valueTwo = FeetToCMConvert(tempValueTwo || tempValueOne);
    //   }
    // }
    console.log("values", values);

    await createAttribute({
      variables: {
        data: {
          attribute: values?.attribute?.key,
          valueOne: values.valueOne,
          valueTwo: values.valueTwo || values.valueOne,
        },
      },
    }).then(() => {
      refetch();
      setVisible(false);
      setType(null)
      // setSelectedAttributeisHeight(false)
    });
  };

  const [form] = useForm();
  const [type, setType] = useState<Enum_Activity_Type | any>();
  // const [selectedAttributeisHeight, setSelectedAttributeisHeight] = useState<
  //   null | boolean
  // >(null);
  const [selectFeet, setSelectFeet] = useState<boolean>(true);
  return (
    <Content>
      <Button onClick={() => setVisible(true)}>Add Attribute Value</Button>

      <Space style={{ display: "flex", alignItems: 'center', margin: '8px 0 8px 0' }}>
        <Input
          placeholder="Search Name"
          onChange={e => {
            if (tableData) {
              const filteredData = data?.attributeValues?.data?.filter((entry: any) =>
                entry?.attributes?.attribute?.data?.attributes?.name.toLowerCase().includes(e.target.value.toLowerCase())
              );
              setTableData(filteredData);
              // console.log('event', e.target.value, filteredData)
            }
          }}
        />
      </Space>

      <Table
        rowKey="id"
        loading={attributeValueLoading}
        dataSource={tableData || []}
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "Name",
            dataIndex: "attributes",
            key: "attributes",
            render: (value, record, index) =>
              value?.attribute?.data?.attributes?.name,
          },
          {
            title: "Value One",
            dataIndex: "attributes",
            key: "attributes",
            render: (value, record, index) => value?.valueOne,
          },
          {
            title: "Value Two",
            dataIndex: "attributes",
            key: "attributes",
            render: (value, record, index) => value?.valueTwo,
          },
        ]}
        pagination={{
          position: ["bottomCenter"],
        }}
      />
      <Modal
        destroyOnClose
        open={visible}
        onCancel={() => {
          setVisible(false)
          setType(null)
          // setSelectedAttributeisHeight(false)
          setSelectFeet(true)
        }}
        footer={false}
      >
        <Form onFinish={onFinish} layout="vertical">
          <Form.Item
            name="attribute"
            label="Attribute"
            rules={[{ required: true }]}
          >
            <Select
              size="large"
              showSearch
              labelInValue
              onSelect={(e) => {
                setType(
                  attributes?.attributes?.data?.find((att) => att?.id === e?.key)
                    ?.attributes?.type
                );

                // e?.label === "Height"
                //   ? setSelectedAttributeisHeight(true)
                //   : setSelectedAttributeisHeight(false);
                // console.log("attributesData===>", e);
              }}
            >
              {attributes?.attributes?.data?.map((key) => {
                return (
                  <Select.Option value={key?.id} key={key?.id}>
                    {key.attributes?.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          {/* {selectedAttributeisHeight && (
            <Switch
              defaultChecked
              onChange={(checked) => setSelectFeet(checked)}
            />
          )} */}
          <Form.Item
            name="valueOne"
            label='Value'
            // {
            //   selectedAttributeisHeight
            //     ? selectFeet
            //       ? "Value(in Feet)"
            //       : "Value(in cm)"
            //     : "Value"
            // }
            rules={[{ required: true, message: 'Please enter a value' }, ({ getFieldValue }) => ({
              validator(_, value) {
                if (type === 'SINGLE')
                  if (checkDuplicateSingleValue(getFieldValue('attribute'), value) !== 0) {
                    return Promise.reject(new Error('Enter new value!'));
                  }
                  else if (type === 'MIN_MAX') {
                    return Promise.resolve();
                  }
                return Promise.resolve();

              },
            })
            ]}
          >
            <Input size="large" />
          </Form.Item>

          {type === "MIN_MAX" && <Form.Item
            // hidden={type !== "MIN_MAX"}
            rules={[{ required: type === "MIN_MAX" }, ({ getFieldValue }) => ({
              validator(_, value) {
                if (type === 'MIN_MAX' && checkDuplicate_MIN_MAX_Values(getFieldValue('attribute'), getFieldValue('valueOne'), getFieldValue('valueTwo')) !== 0) {
                  return Promise.reject(new Error('Range Exists Already. Enter new range!'));
                }
                else if (!value || getFieldValue('valueOne') === value) {
                  return Promise.reject(new Error('MIN and MAX value cannot be same!'));
                }
                else if (parseInt(getFieldValue('valueOne')) > parseInt(value)) {
                  return Promise.reject(new Error('MAX value should be greater!'));
                }
                return Promise.resolve();

              },
            })]}
            name="valueTwo"
            label="Max Value"
          // {selectedAttributeisHeight
          //   ? selectFeet
          //     ? "Max Value(in Feet)"
          //     : "Max Value(in cm)"
          //   : "Max Value"}
          >
            <Input size="large" />
          </Form.Item>}

          <Button loading={creating} htmlType="submit">
            SUBMIT
          </Button>
        </Form>
      </Modal>
    </Content >
  );
};
