export const environment = {
  userPoolId: process.env.REACT_APP_USER_POOL_ID,
  webClientId: process.env.REACT_APP_COGNITO_WEB_CLIENT_ID,
  identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
  authFlow: process.env.REACT_APP_AUTH_FLOW,
  region: process.env.REACT_APP_REGION,
  baseUrl: process.env.REACT_APP_BASE_URL,
  // appUrl: process.env.REACT_APP_URL,
  s3bucketUrl: process.env.REACT_APP_BUCKET_BASE_URL,
  s3bucketName: process.env.REACT_APP_BUCKET_NAME,
};
