import { useEffect, useState } from "react";
import "./App.css";
import { AuthContext } from "./contexts/AuthContext";
import { Auth } from "aws-amplify";

import { MainRoute } from "./route/Route";

export type checkToken = () => Promise<boolean>;

function App() {
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false);

  console.log("isAuthenticated", isAuthenticated);
  const [isLoading, setIsLoading] = useState(true);

  const token: checkToken = async () => {
    // setAuthenticated('loading');
    try {
      const session = await Auth.currentSession();
      // console.log('session',session) 
      if (session) {
        setAuthenticated(true);
        return true;
      }
      // console.log('UserToken Success', session);
      // if (session) {
      //   return true
      // }
      // else
      //   setAuthenticated(false);
      //   return false
    } catch (e) {
      // console.log("UserToken Error", e);
      // //isAuthenticated flag set to false only on logout
      // // setAuthenticated(false);
      // const session = await Auth.currentSession();
      // console.log("UserToken Success", session);
      // if (session) {
      //   setAuthenticated(true);
      //   return true;
      // } else setAuthenticated(false);
      return false;
    } finally {
      setIsLoading(false);
    }
    return false;
  };
  useEffect(() => {
    token();
  }, []);

if(isLoading){
  return <h1>loading</h1>
}

  return (
    <AuthContext.Provider value={{ isAuthenticated, setAuthenticated, token }}>
      <MainRoute />
    </AuthContext.Provider>
  );
}

export default App;
