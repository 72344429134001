import {
  DeleteOutlined,
  EditOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  DeleteNodeDocument,
  Enum_Activity_Type,
  useCreateNodeMutation,
  useDeleteNodeMutation,
  useGetAttributeValuesQuery,
  useGetFitTypesQuery,
  useGetNodesQuery,
} from "../GraphQl/generated/graphql";
import Search from "antd/es/input/Search";
import { useDebounce } from "use-debounce";
import { API } from "aws-amplify";
import { EditNodeForm } from "./EditNodeForm";
import { CMToFeetConvert, FeetToCMConvert } from "../utils/FeetToCMConvert";
import { Product } from "../types/ProductTypes";
import { useForm } from "antd/es/form/Form";

export const Nodes = () => {
  const [search, setSearch] = useState("");
  const [productList, setProductList] = useState<Product[]>([]);
  const [isNodesProductNameListLoading, setIsNodesProductNameListLoading] =
    useState<boolean>(true);
  const [editModalVisible, setEditModalVisible] = useState<boolean>(false);
  const [nodeRowData, setNodeRowData] = useState({});
  const [payload, setPayload] = useState<null | Record<string, any>>(null);

  const [form] = useForm();

  // const { data: fitData, loading: fitTypeLoading } = useGetFitTypesQuery();
  // console.log("fit data: ", fitData?.attributeValues?.data);

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts = async () => {
    try {
      let url = `/product_management?limit=2000&page=1/`;
      const response = await API.get(`latidoApi`, url, { response: true });
      setProductList(response?.data?.data?.data);
    } catch (error) {
      return false;
    } finally {
      setIsNodesProductNameListLoading(false);
    }
  };

  const [debounceSearch] = useDebounce(search, 1000);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
  });
  const { data: attributeValues } = useGetAttributeValuesQuery({
    variables: {
      pagination: {
        limit: -1,
      },
    },
  });

  const {
    data,
    loading: nodeTableLoading,
    refetch,
    updateQuery,
  } = useGetNodesQuery({
    variables: {
      pagination: {
        ...pagination,
      },
      filters: payload,
    },
  });
  // useEffect(() => {
  //   setPagination({
  //     page: 1,
  //     pageSize: 10,
  //   });
  // }, [debounceSearch]);

  const [createAttribute, { data: createData, loading: creating }] =
    useCreateNodeMutation();

  const [DeleteNodeDocument, { data: deleteData, loading, error }] =
    useDeleteNodeMutation();

  const [visible, setVisible] = useState(false);
  const onFinish = async (values: any) => {
    const preparePayload = () => {
      const formKeys = ["product", "height", "fit", "weight", "age", "gender"];
      const filteredKeys: any = [];

      formKeys.forEach((el) => {
        if (values[el] && el !== "product") {
          filteredKeys.push({
            attribute_values: {
              id: {
                eq: values[el],
              },
            },
          });
        } else if (values[el] && el === "product") {
          filteredKeys.push({
            products: {
              title: {
                contains: values[el],
              },
            },
          });
        }
      });

      return filteredKeys.length > 0 ? { and: filteredKeys } : {};
    };

    const preparedPayload = preparePayload();

    // Directly use the new pagination
    const newPagination = {
      page: 1,
      pageSize: 10,
    };

    setPayload(preparedPayload);

    setPagination(newPagination);

    // Ensure refetch can accept variables as parameters
    refetch({
      filters: preparedPayload,
      pagination: newPagination,
    });
  };

  const [type, setType] = useState<Enum_Activity_Type | any>();

  const editNodeRowRecord = (record: {}) => {
    // console.log('record row', record)
    setEditModalVisible(true);
    setNodeRowData(record);
  };

  const deleteNodeRecord = async (record: Record<string, any>) => {
    const id = record?.id;

    const data = await DeleteNodeDocument({
      variables: { id },
    });

    if (data) {
      notification.success({
        message: `Node ${id} deleted!`,
        placement: "top",
      });
      refetch();
    }
  };

  const filteredDataSource = (data?.nodes?.data || [])?.filter(
    (node) => (node?.attributes?.products?.length || 0) > 0
  );

  const { data: heightData, loading: heightLoading } =
    useGetAttributeValuesQuery({
      variables: {
        pagination: {
          limit: -1,
        },
        filters: {
          attribute: {
            label: {
              eq: "Height",
            },
          },
        },
      },
    });
  const { data: fitData, loading: fitLoading } = useGetAttributeValuesQuery({
    variables: {
      pagination: {
        limit: -1,
      },
      filters: {
        attribute: {
          label: {
            eq: "Fit",
          },
        },
      },
    },
  });
  const { data: weightData, loading: weightLoading } =
    useGetAttributeValuesQuery({
      variables: {
        pagination: {
          limit: -1,
        },
        filters: {
          attribute: {
            label: {
              eq: "Weight",
            },
          },
        },
      },
    });
  const { data: ageData, loading: ageLoading } = useGetAttributeValuesQuery({
    variables: {
      pagination: {
        limit: -1,
      },
      filters: {
        attribute: {
          label: {
            eq: "Age",
          },
        },
      },
    },
  });
  const { data: genderData, loading: genderLoading } =
    useGetAttributeValuesQuery({
      variables: {
        pagination: {
          limit: -1,
        },
        filters: {
          attribute: {
            label: {
              eq: "Gender",
            },
          },
        },
      },
    });

  // console.log("heightData: ", heightData);

  const onFinishModal = async (values: any) => {
    await createAttribute({
      variables: {
        data: {
          attribute_values: values.attribute_values,
          output: values.output,
          outputLevel: values.outputLevel,
          products: values.products.map((item: any) => {
            const updatedValue = { title: item?.name };
            return updatedValue;
          }),
          title: values.title,
        },
      },
    }).then(() => {
      refetch();
      setVisible(false);
    });
  };

  return (
    <Content>
      <Button
        style={{
          marginBottom: "1rem",
        }}
        onClick={() => setVisible(true)}
      >
        Add Nodes
      </Button>
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
      >
        <Space direction="horizontal">
          <Form.Item name="product">
            <Select
              allowClear
              placeholder="Product"
              showSearch
              loading={isNodesProductNameListLoading}
            >
              {productList?.map((el) => (
                <Select.Option key={el._id} value={el.name}>
                  {el.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item name="height">
            <Select
              allowClear
              placeholder="Height Range"
              loading={heightLoading}
            >
              {heightData?.attributeValues?.data?.map((el) => {
                const val = `${el?.attributes?.valueOne} - ${el?.attributes?.valueTwo}`;
                return (
                  <Select.Option key={el.id} value={el.id}>
                    {val}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="fit">
            <Select
              allowClear
              placeholder="Fit Type"
              loading={fitLoading}
              style={{ minWidth: "10rem" }}
            >
              {fitData?.attributeValues?.data?.map((el) => {
                const val = `${el.attributes?.valueOne} - ${el.attributes?.valueTwo}`;
                return (
                  <Select.Option key={el.id} value={el.id}>
                    {val}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="weight">
            <Select
              allowClear
              placeholder="Weight Data"
              loading={weightLoading}
              style={{ minWidth: "10rem" }}
            >
              {weightData?.attributeValues?.data?.map((el) => {
                const val = `${el.attributes?.valueOne} - ${el.attributes?.valueTwo}`;
                return (
                  <Select.Option key={el.id} value={el.id}>
                    {val}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="age">
            <Select
              allowClear
              placeholder="Age Data"
              loading={ageLoading}
              style={{ minWidth: "10rem" }}
            >
              {ageData?.attributeValues?.data?.map((el) => {
                const val = `${el.attributes?.valueOne} - ${el.attributes?.valueTwo}`;
                return (
                  <Select.Option key={el.id} value={el.id}>
                    {val}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item name="gender">
            <Select
              allowClear
              loading={genderLoading}
              placeholder="Gender Data"
              style={{ minWidth: "10rem" }}
            >
              {genderData?.attributeValues?.data?.map((el) => {
                const val = `${el.attributes?.valueOne} - ${el.attributes?.valueTwo}`;
                return (
                  <Select.Option key={el.id} value={el.id}>
                    {val}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Space>
      </Form>
      <Table
        loading={nodeTableLoading}
        rowKey="id"
        columns={[
          {
            title: "ID",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "Name",
            dataIndex: "attributes",
            key: "attributes",
            render: (value, record, index) => value?.title,
          },
          {
            title: "Output",
            dataIndex: "attributes",
            key: "attributes",
            render: (value, record, index) => value?.output,
          },
          {
            title: "Output Label",
            dataIndex: "attributes",
            key: "attributes",
            render: (value, record, index) => value?.outputLevel,
          },
          {
            title: "Products",
            dataIndex: "attributes",
            key: "attributes",
            render: (value, record, index) =>
              value?.products?.map((d: any) => d?.title)?.join(", "),
          },
          {
            title: "Attribute Values",
            dataIndex: "attributes",
            key: "attributes",
            render: (value, record, index) => (
              <Space style={{ flexWrap: "wrap" }}>
                {value.attribute_values.data?.map((ab: any) => {
                  return (
                    <Tag key={ab?.id}>
                      {`${ab.attributes.attribute.data.attributes.name} (${ab.attributes?.valueOne} - ${ab.attributes?.valueTwo})`}{" "}
                    </Tag>
                  );
                })}
              </Space>
            ),
          },
          {
            title: "",
            key: "edit",
            render: (text, record, index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    gap: "1rem",
                  }}
                >
                  <Button onClick={() => editNodeRowRecord(record)}>
                    <EditOutlined />
                  </Button>
                  <Popconfirm
                    title="Are you sure you want to delete this Node?"
                    onConfirm={() => deleteNodeRecord(record)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      style={{
                        color: "red",
                      }}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Popconfirm>
                </div>
              );
            },
          },

          // {
          //   title: "Value Two",
          //   dataIndex: "attributes",
          //   key: "attributes",
          //   render: (value, record, index) => value?.valueTwo,
          // },
        ]}
        dataSource={filteredDataSource}
        pagination={{
          position: ["bottomCenter"],
          onChange(page, pageSize) {
            setPagination({ page: page, pageSize: pageSize });
          },
          pageSize: pagination?.pageSize,
          total: data?.nodes?.meta?.pagination?.total,
          current: pagination.page,
        }}
      />
      <Modal
        destroyOnClose
        open={visible}
        onCancel={() => setVisible(false)}
        footer={false}
      >
        <Form onFinish={onFinishModal} layout="vertical">
          <Form.Item
            name="attribute_values"
            label="Attribute"
            rules={[{ required: true }]}
          >
            <Select
              size="large"
              showSearch
              mode="multiple"
              // onSelect={(e) =>
              //   setType(
              //     attributes?.attributes?.data?.find((att) => att?.id === e)
              //       ?.attributes?.type
              //   )
              // }
            >
              {attributeValues?.attributeValues?.data?.map((key) => {
                return (
                  <Select.Option value={key?.id} key={key?.id}>
                    {`${key.attributes?.attribute?.data?.attributes?.name} (${key.attributes?.valueOne} - ${key.attributes?.valueTwo})`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="title"
            label="Node Title"
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>
          <Form.Item name="output" label="Output" rules={[{ required: true }]}>
            <InputNumber size="large" />
          </Form.Item>
          <Form.Item
            name="outputLevel"
            label="Output Level"
            rules={[{ required: true }]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.List name="products" initialValue={[""]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 8,
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        name={[index, "name"]}
                        label="Products"
                        // rules={[
                        //   { required: true, message: "Missing Product" },
                        // ]}
                      >
                        <Select
                          size="large"
                          showSearch
                          loading={isNodesProductNameListLoading}
                          // onSelect={(e) => { }}
                          // onSelect={(e) =>
                          //   setType(
                          //     attributes?.attributes?.data?.find((att) => att?.id === e)
                          //       ?.attributes?.type
                          //   )
                          // }
                          style={{ width: 200 }}
                        >
                          {(Array.isArray(productList) ? productList : [])?.map(
                            (item) => {
                              // console.log('value here', item)
                              return (
                                <Select.Option
                                  value={item?.name}
                                  key={item?.name}
                                >
                                  {/* {console.log('item.name', item.name)} */}
                                  {`${item?.name}${
                                    item?.gender === "unisex"
                                      ? ""
                                      : `_${item?.gender}`
                                  }`}
                                </Select.Option>
                              );
                            }
                          )}
                        </Select>
                      </Form.Item>

                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                        style={{ paddingLeft: "20px" }}
                      />
                    </Space>
                  );
                })}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add field
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Button loading={creating} htmlType="submit" size="large">
            SUBMIT
          </Button>
        </Form>
      </Modal>

      <Modal
        destroyOnClose
        open={editModalVisible}
        onCancel={() => {
          setEditModalVisible(false);
          setNodeRowData({});
          // form.resetFields();
        }}
        footer={false}
      >
        <EditNodeForm
          listOfAttribute={attributeValues?.attributeValues?.data}
          productSelectOptions={productList}
          nodeRowData={nodeRowData}
          setEditModalVisible={setEditModalVisible}
        />
      </Modal>
    </Content>
  );
};
