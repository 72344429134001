import {ApolloClient, ApolloLink, HttpLink, InMemoryCache} from '@apollo/client';

// export const SERVER_URL = 'https://sports.mules.cloud';
export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const tokenForProductList = process.env.REACT_APP_PRODUCT_MUTATION_TOKEN;


const secondLink = new HttpLink({
  uri: 'https://old.nsc.gov.np/graphql',
  // other link options...
});

// Create Second Link
const firstLink = new HttpLink({
  uri: `${SERVER_URL}/graphql`,
  headers: {
    authorization:
    `Bearer ${tokenForProductList}`
  },
  // other link options...
});
// AssociationEntity
export const apolloClient = new ApolloClient({
  // uri: 'https://nsc.beeaver.work/graphql',
  link: ApolloLink.split(
    operation => operation.getContext().clientName === 'wp',
    secondLink,
    firstLink,
  ),
  cache: new InMemoryCache({
    typePolicies: {
      Association: {
        merge: true,
      },
      Game: {
        merge: true,
      },
      Team: {
        merge: true,
      },
      Event: {
        merge: true,
      },
      Player: {
        merge: true,
      },
      Tournament: {
        merge: true,
      },
      Itournament: {
        merge: true,
      },
      NationalTeam: {
        merge: true,
      },
      Department: {
        merge: true,
      },
      Post: {
        merge: true,
      },
    },
  }),
});
