import {API, Auth} from 'aws-amplify';

const token = async () => {
  const session = await Auth.currentSession();
  const token = await session.getIdToken().getJwtToken();
  // console.log('get token',token)
  return token;
};

export async function setAuthorizationHeaders() {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  return token;
}

async function get(name: any, url: any, options: {}) {
  let opt = modifyOpts(options || {});

  try {
    const tkn = await token();
    console.log('TOKEN', tkn)
    return API.get(name, url, {
      ...opt,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: tkn,
      },
      response: true,
    }).catch((error) => {
      if (error?.response?.status === 401) {
        errorIntercept(error);
      }
    });
  } catch (e) {
    return API.get(name, url, {
      ...opt,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      response: true,
    }).catch((error) => {
      if (error?.response?.status === 401) {
        errorIntercept(error);
      }
    });
  }

  // const tkn = await token();
  // return API.get(name, url, {
  //   ...opt,
  //   headers: {
  //     Accept: 'application/json',
  //     'Content-Type': 'application/json',
  //     Authorization: tkn,
  //   },
  //   response: true,
  // }).catch((error) => {
  //   if (error?.response?.status === 401) {
  //     errorIntercept(error);
  //   }
  // });
}

const updateSession = (session: {
  accessToken: {jwtToken: string};
  idToken: {jwtToken: string};
}) => {
  localStorage.setItem('accessToken', session?.accessToken?.jwtToken);
  localStorage.setItem('idToken', session.idToken.jwtToken);
  window.location.reload();
};

const refreshToken = async () => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    const currentSession = currentUser.signInUserSession;
    currentUser.refreshSession(
      currentSession.refreshToken,
      (err: any, session: any) => {
        //window.myses = session;
        updateSession(session);
      },
    );
  } catch (e) {}
};

const errorIntercept = (e: undefined) => {
  refreshToken();
};

const modifyOpts = (opt: any) => {
  return {
    ...opt,
    headers: {Authorization: token()},
    response: true,
  };
};

export async function post(name: any, url: any, options: any) {
  let opt = modifyOpts(options || {}); 
  const tkn = await token();
  return API.post(name, url, {
    ...opt,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: tkn,
    },
  }).catch((error) => {
    if (error?.response?.status === 401) {
      errorIntercept(error);
    }
    return error;
  });
}

async function put(name: any, url: any, options: any) {
  let opt = modifyOpts(options || {});
  const tkn = await token();
  return API.put(name, url, {
    ...opt,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: tkn,
    },
  }).catch((error) => {
    if (error?.response?.status === 401) {
      errorIntercept(error);
    }
  });
}
export async function patch(name: any, url: any, options: any) {
  let opt = modifyOpts(options || {});
  const tkn = await token();
  return API.patch(name, url, {
    ...opt,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: tkn,
    },
  }).catch((error) => {
    if (error?.response?.status === 401) {
      errorIntercept(error);
    }
  });
}

async function del(name: any, url: any, options: any) {
  let opt = modifyOpts(options || {});
  const tkn = await token();
  return API.del(name, url, {
    ...opt,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: tkn,
    },
  }).catch((error) => {
    if (error?.response?.status === 401) {
      errorIntercept(error);
    }
  });
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {get, put, del, post, patch};
