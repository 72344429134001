import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Select, Space } from "antd";

interface IFormListItemProps {
  productSelectOptions: any;
  nodeRowData: any;
}

export const CustomFormListItem: React.FC<IFormListItemProps> = ({
  productSelectOptions,
  nodeRowData,
}) => {
  return (
    <Form.List
      name="products"
      initialValue={nodeRowData?.attributes?.products.map((item: any) => {
        return { name: item?.title };
      })}
    >
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, ...restField }, index) => {
            return (
              <Space
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 8,
                }}
                align="baseline"
              >
                <Form.Item
                  name={[index, "name"]}
                  label="Products"
                  // rules={[
                  //   { required: true, message: "Missing Product" },
                  // ]}
                >
                  <Select
                    size="large"
                    defaultValue={
                      nodeRowData?.attributes?.products[index]?.title
                    } // Set the defaultValue here
                    showSearch
                    // loading={isNodesProductNameListLoading}
                    // onSelect={(e) => { }}
                    // onSelect={(e) =>
                    //   setType(
                    //     attributes?.attributes?.data?.find((att) => att?.id === e)
                    //       ?.attributes?.type
                    //   )
                    // }
                    style={{ width: 200 }}
                  >
                    {(Array.isArray(productSelectOptions)
                      ? productSelectOptions
                      : []
                    )?.map((item) => {
                      return (
                        <Select.Option value={item?.name} key={item?.id}>
                          {/* {console.log('item.name', item.name)} */}
                          {`${item?.name}${
                            item?.gender === "unisex" ? "" : `_${item?.gender}`
                          }`}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <MinusCircleOutlined
                  onClick={() => remove(name)}
                  style={{ paddingLeft: "20px" }}
                />
              </Space>
            );
          })}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Add field
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};
