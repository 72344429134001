import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from "../pages/LoginPage";
import SizeModuleHome from "../pages/SizeModuleHome";
import { useCallback, useContext, useEffect, useState } from "react";
import { ProtectedRoute } from "./ProtectedRoute";
import { Auth } from "aws-amplify";
import { AuthContext } from "../contexts/AuthContext";


export const MainRoute = () => {
    useEffect(() => {
        token();
    }, []);

    const authContext = useContext(AuthContext);
    if (!authContext) return null;

    const { isAuthenticated, setAuthenticated, token } = authContext









    return <Routes>
        <Route path="/"
            element={
                <ProtectedRoute checkToken={token}>
                    <SizeModuleHome />
                </ProtectedRoute>
            }
        />
        
        <Route path="/login" element={<LoginPage
        />
        }
        />

    </Routes>
}